:root {
  --primary-blue: #42A5F5;
  --accent-blue: #29b6f6;

  --text-color: #000000;
  --text-accent-color: #8b8b8b;
  --text-color-blue: #e3f2fd;
  
  --background-color: #ffffff;

  --red: #e57373;
  --better-red: #f44336;
  --green: #4caf50;
  --orange: #ff8c00;
  --font: 'Lato', sans-serif;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #E1E2E1;
  font-family: 'Lato', sans-serif;
}

main {
  margin: 20px;
  padding: 20px;
  background-color: var(--background-color);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 2px;
}

.main-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.main-bar h1 {
  margin: 0;
  border-bottom: 2px solid var(--primary-blue);
}

.main-bar--h2 h2 {
  margin: 0;
  border-bottom: 2px solid var(--primary-blue);
}
.main-bar--h2 {
  margin-top: 15px;
}

.main-bar--buttons {
  display: flex;
}

.main-bar--buttons > .button, .button--delete {
  margin-left: 10px;
}


.button, .button--delete, .button--pay {
  text-decoration: none;
  background-color: var(--background-color);
  color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  padding: 10px 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.button--fill {
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.button:hover {
  border: 1px solid var(--accent-blue);
  background-color: var(--accent-blue);
  color: var(--background-color);
}

.button--delete:hover{
  background-color: var(--red);
  border: 1px solid var(--red);
  color: var(--background-color);
}

.button--pay:hover{
  background-color: var(--green);
  border: 1px solid var(--green);
  color: var(--background-color);
}

svg {
  font-size: 1.1rem;
  margin: 0 auto;
}


label {
  font-size: 1.2rem;
}

input, textarea, select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--primary-blue);
  border-radius: 2px;
  font-size: 1.1rem;
  font-family: var(--font);
}

textarea {
  line-height: 1.5rem;
}

a {
  text-decoration: none;
}

.Toastify__toast--info {
  background-color: var(--primary-blue);
  color: var(--text-color-blue);
}

@media print {
  main {
    display: none;
  }
}