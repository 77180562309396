.login-card {
  margin: 10% 35%;
  background-color: var(--background-color);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 2px;
}

.login-card--field {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
}

.login-card--field label {
  margin-bottom: 5px;
}

.login-card--logo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 3rem;
  width: 100%;
  color: var(--primary-blue);
  margin-bottom: 20px;
}

.login-card--logo svg {
  height: 4rem;
  fill: var(--primary-blue);
}

.login-card--logo span {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}

.login-card button {
  margin-top: 10px;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}

.login-card-error {
  color: var(--red);
  border-radius: 2px;
  border: 1px solid var(--red);
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
}